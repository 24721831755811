import { Component, Output, EventEmitter, Input } from '@angular/core'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { UserService } from '@service/user/user.service'

import { DrawerService } from '@service/drawer.service'
import { ForgotPasswordDialogComponent } from '../../forgot-password-dialog/forgot-password-dialog.component'
import { environment } from '@environments/environment'
import { ConfirmDialogComponent } from '@modal/confirm-dialog/confirm-dialog.component'
import { ScrollService } from '@service/scroll.service'
import { StateService } from '@service/state.service'
import { MobileService } from '@service/mobile.service'

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent {

  public environment = environment

  public loading: boolean = false
  public loginForm: FormGroup

  @Output() public status = new EventEmitter()

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public MatDialog: MatDialog,
    private drawerService: DrawerService,
    private ScrollService: ScrollService,
    private StateService: StateService,
    public mobileService: MobileService,
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    })
  }

  public loginWithGoogle() {
    window.location.href = (<any>window).serverUrl + '/api/auth/google/redirect'
  }

  public loginWithFacebook() {
    window.location.href = (<any>window).serverUrl + '/api/auth/facebook/redirect'
  }

  public switch(e) {
    this.status.emit(e)
  }

  public forgotPw() {
    this.dialogRef.close()
    this.MatDialog.open(ForgotPasswordDialogComponent, {
      panelClass: 'black-centered-dialog'
    })
  }

  public onSubmit() {

    this.loading = true
    this.userService.login(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this.drawerService.close()
        this.dialogRef.close()
        this.StateService.goto('/news')
          .then(() => {

            setTimeout(() => {
              this.ScrollService.scrollToTop()
            }, 200);

          })
      })
      .catch(err => {

        if (err == 'ACCOUNT_NOT_CONFIRMED') {
          const confirmConfig = {
            text: 'Deine Emailadresse ist noch nicht bestätigt. Wir senden dir in den nächsten Minuten eine neue Bestätigungsmail. Bitte klicke den Link darin.',
            buttons: [
              {
                text: 'OK',
                value: 'throw',
              },
            ],
          }
          const dialogRef = this.MatDialog.open(ConfirmDialogComponent, {
            data: confirmConfig,
            disableClose: false,
            panelClass: ConfirmDialogComponent.cssClass
          })
        }

        if (err == 'NO_MATCH') {
          const confirmConfig = {
            text: 'Benutzername oder Passwort sind falsch. Bitte prüfe exakt Groß- und Kleinschreibung und Leerzeichen.',
            buttons: [
              {
                text: 'OK',
                value: 'throw',
              },
            ],
          }
          const dialogRef = this.MatDialog.open(ConfirmDialogComponent, {
            data: confirmConfig,
            disableClose: false,
            panelClass: ConfirmDialogComponent.cssClass
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  public close() {
    this.dialogRef.close()
  }
}
