<mat-dialog-content [class.isMobile]="mobileService.isMobile">

  <!-- dialog close "button" -->
  <!-- <div style="text-align: right;">
    <i class="far fa-times-circle" style="color: red; font-size: 35px; cursor: pointer;" (click)="close()"></i>
  </div> -->

  <login-dialog *ngIf="dialog_d == 'login'"
    (status)="statusChange($event)"></login-dialog>

  <app-forgot-password-dialog *ngIf="dialog_d == 'forgot-pw'"
    (status)="statusChange($event)"></app-forgot-password-dialog>

  <app-facebook-dialog *ngIf="dialog_d == 'fb'"
    (status)="statusChange($event)"></app-facebook-dialog>

  <registration-dialog *ngIf="dialog_d == 'registration'"
    (status)="statusChange($event)"
    [clickGuarded]="clickGuarded"></registration-dialog>

  <div *ngIf="dialog_d == 'agb'">
    <p>
      <button class="orange-button"
        (click)="statusChange('login')">
        < zurück
          zur
          Anmeldung</button>
    </p>
    <app-impressum [inline]="true"></app-impressum>
    <app-agb [inline]="true"></app-agb>
  </div>

</mat-dialog-content>