import { Injectable } from '@angular/core'
import { RestService } from '@service/rest/rest.service'
import { Category } from '@model/category/category'
import { CategoryType } from '@enum/category-type'

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {

  // cache
  private categories: { [type: string]: Category[] } = {}

  constructor(
    private restService: RestService,
  ) { }

  public videocat: Category = null
  public eventcat: Category = null

  public getCategories(type: CategoryType): Promise<Category[]> {
    return new Promise((resolve, reject) => {

      if (typeof this.categories[type] != 'undefined' && this.categories[type]) {

        // always deliver deep clone in case receiver changes it
        let clone = JSON.parse(JSON.stringify(this.categories[type]))

        resolve(clone)
      }
      else {

        this.getCategoriesFromServer(type + '/get', type)
          .then(() => {

            // always deliver deep clone in case receiver changes it
            let clone = JSON.parse(JSON.stringify(this.categories[type]))

            resolve(clone)
          })
      }
    })
  }

  public getCategoryById(id: number, type: CategoryType): Promise<Category | null> {
    return new Promise((resolve, reject) => {
      // category already fetched -> search it
      if (typeof this.categories[type] != 'undefined' && this.categories[type]) {
        resolve(this.searchForCachedCategoryByID(id, type))
      }
      // category not yet fetched -> fetch it before searching
      else {
        this.getCategoriesFromServer(type + '/get', type)
          .then(() => {
            resolve(this.searchForCachedCategoryByID(id, type))
          })
      }
    })
  }

  /*
   * this searches for a category by id in the already cached categories in this.categories
   */
  private searchForCachedCategoryByID(id: number, type: CategoryType): Category | null {
    if (typeof this.categories[type] != 'undefined' && this.categories[type]) {
      const cats = this.categories[type].filter(c => c.id == id)
      if (cats.length > 0) {
        return cats[0]
      }
    }
    return null
  }

  public getCategoriesFromServer(url: string, type: CategoryType): Promise<void> {

    return new Promise((resolve, reject) => {
      this.restService.post(url, {}, null, false)
        .then((categories: Category[]) => {

          this.categories[type] = categories

          // find blog special cats
          if (type === CategoryType.blog) {

            for (let cat of categories) {
              if (cat.name.toLocaleLowerCase().includes("video")) {
                this.videocat = cat
              }
              if (cat.name.toLowerCase() == "veranstaltungen")
                this.eventcat = cat
            }
          }

          resolve()
        })
        .catch(err => reject(err))
    })
  }
}